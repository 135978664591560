
import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { AxiosError } from 'axios';
import GButton from '@/components/gsk-components/GskButton.vue';
import GImageUpload from '@/components/gsk-components/GskImageUpload.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import ListingTemplateEditor from '@/components/admin/ListingTemplateEditor.vue';
import {
  getAdminListingTypes,
  createAdminListingType,
  updateAdminListingType,
  deleteAdminListingType,
} from '@/api/listing-types.api';
import { NumberField, TextField, ImageUploadField } from '@/components/form/form.types';
import { ListingType, ListingTypeCreateData } from '@/types/listing-types.types';
import { openErrorSnackbar } from '../../utils/components';
import defaultListingJson from './default-listing.json';
import { ListingsModule } from '../../store/modules/listings.module';

type NewListingForm = [
  TextField<'listingTypeName'>,
  TextField<'mnemonic'>,
  TextField<'listingTypeDescription'>,
  NumberField<'sequenceNumber'>,
  ImageUploadField<'defaultIcon'>,
  TextField<'listingTemplate'>,
];

type EditListingForm = [
  TextField<'listingTypeName'>,
  TextField<'mnemonic'>,
  TextField<'listingTypeDescription'>,
  NumberField<'sequenceNumber'>,
  ImageUploadField<'defaultIcon'>,
  TextField<'listingTemplate'>,
];

@Component({
  components: {
    GButton,
    MaxWidth,
    Promised,
    ValidatedFormDialog,
    DeleteDialog,
    ListingTemplateEditor,
    GskImageUpload: GImageUpload,
  },
})
export default class ListingTypesView extends Vue {
  newListingOpen = false;
  file = [];
  open = false;
  listingTypes: Promise<ListingType[]> | null = null;

  newListingForm: NewListingForm = [
    {
      key: 'listingTypeName',
      label: 'Listing Name',
      type: 'text',
      value: '',
      required: true,
      attrs: {
        maxLength: '20',
      },
      validation: {
        rules: 'required|max:20',
      },
    },
    {
      key: 'mnemonic',
      label: 'Listing Mnemonic',
      type: 'text',
      value: '',
      required: true,
      helpText: 'UPPERCASE, no spaces',
      attrs: {
        maxLength: '50',
      },
      validation: {
        rules: 'required|max:50|regex:^[A-Z]+$',
      },
    },
    {
      key: 'listingTypeDescription',
      label: 'Listing Description',
      type: 'long-text',
      value: '',
      required: true,
      attrs: {
        maxLength: '255',
      },
      validation: {
        rules: 'required|max:255',
      },
    },
    {
      key: 'sequenceNumber',
      label: 'Sequence Number',
      type: 'number',
      value: 1,
      required: true,
      attrs: {
        maxLength: '3',
      },
      validation: {
        rules: 'required|integer|min_value:1|max_value:999',
      },
    },
    {
      key: 'defaultIcon',
      label: 'Default Icon',
      type: 'image-upload',
      value: [],
      required: true,
      validation: {
        rules: 'required',
      },
    },
    {
      key: 'listingTemplate',
      label: 'Listing Template',
      type: 'long-text',
      value: JSON.stringify(defaultListingJson, null, 2),
      required: true,
      helpText: 'Default listing template generated, edit after creation.',
      attrs: {
        persistenthelpertext: '1',
        disabled: '1',
      },
      validation: {
        rules: 'required',
      },
    },
  ];
  get newListingFormData(): ListingTypeCreateData {
    const out: ListingTypeCreateData = {
      mnemonic: '',
      sequenceNumber: -1,
      listingTypeName: '',
      listingTypeDescription: '',
      listingTemplate: '',
      defaultIcon: '',
    };
    this.newListingForm.forEach(f => {
      if (f.key === 'sequenceNumber') {
        out[f.key] = f.value ?? 0;
      } else {
        out[f.key] = f.value;
      }
    });
    return out;
  }

  createListing() {
    this.newListingFormData.listingTemplate = JSON.parse(this.newListingFormData.listingTemplate);
    this.newListingFormData.defaultIcon = this.newListingFormData.defaultIcon[0];

    createAdminListingType(this.newListingFormData)
      .then(() => {
        this.newListingOpen = false;
        this.load();
        ListingsModule.getListingIconTypes();
      })
      .catch(err => {
        openErrorSnackbar.call(this, err.toString());
      });
  }

  editListingOpen = false;
  editListing: ListingType | null = null;
  editListingForm: EditListingForm | null = null;
  setEditListing(listing: ListingType) {
    this.editListing = listing;
    // set form here, see 'setEditFlag' from featureflags.vue
    this.editListingForm = [
      {
        key: 'listingTypeName',
        label: 'Listing Name',
        type: 'text',
        value: listing.listingTypeName,
        required: true,
        attrs: {
          maxLength: '20',
        },
        validation: {
          rules: 'required|max:20',
        },
      },
      {
        key: 'mnemonic',
        label: 'Listing Mnemonic',
        type: 'text',
        value: listing.mnemonic,
        required: true,
        helpText: 'You probably should not be changing this',
        attrs: {
          maxLength: '50',
          disabled: '1',
          persistenthelpertext: '1',
        },
        validation: {
          rules: 'required|max:50|regex:^[A-Z]+$',
        },
      },
      {
        key: 'listingTypeDescription',
        label: 'Listing Description',
        type: 'long-text',
        value: listing.listingTypeDescription,
        required: true,
        attrs: {
          maxLength: '255',
        },
        validation: {
          rules: 'required|max:255',
        },
      },
      {
        key: 'sequenceNumber',
        label: 'Sequence Number',
        type: 'number',
        value: listing.sequenceNumber,
        required: true,
        attrs: {
          maxLength: '3',
        },
        validation: {
          rules: 'required|integer|min_value:1|max_value:999',
        },
      },
      {
        key: 'defaultIcon',
        label: 'Default Icon',
        type: 'image-upload',
        value: [listing.defaultIcon],
        // required: true,
        // validation: {
        //   rules: 'required',
        // },
      },
      {
        key: 'listingTemplate',
        label: 'Listing Template',
        type: 'long-text',
        value: JSON.stringify(listing.listingTemplate, null, 2),
        required: true,
        helpText: 'Disabled, use Edit Template button.',
        attrs: {
          persistenthelpertext: '1',
          disabled: '1',
        },
        validation: {
          rules: 'required',
        },
      },
    ];
    this.editListingOpen = true;
  }

  // Section for editing JSON listing template data
  editTemplateOpen = false;
  setEditTemplate(listing: ListingType) {
    this.editListing = listing;
    this.editTemplateOpen = true;
  }
  // called when monaco JSON editor "save documentation" button is pushed
  editorSave(listing: ListingType): void {
    updateAdminListingType(listing)
      .then(() => {
        this.editTemplateOpen = false;
        this.load();
      })
      .catch(e => {
        openErrorSnackbar.call(this, e.toString());
      });
  }

  get editListingFormData(): ListingType | null {
    if (this.editListing && this.editListingForm) {
      const out: ListingType = {
        listingTypeId: this.editListing.listingTypeId,
        mnemonic: '',
        sequenceNumber: 1,
        listingTypeName: '',
        listingTypeDescription: '',
        listingTemplate: '',
        defaultIcon: '',
      };
      for (const field of this.editListingForm) {
        if (field.key === 'sequenceNumber') {
          out[field.key] = field.value ?? 0;
        } else {
          out[field.key] = field.value;
        }
      }
      out.listingTemplate = JSON.parse(out.listingTemplate);
      out.defaultIcon = out.defaultIcon[0];
      return out;
    }
    return null;
  }

  updateListing() {
    if (this.editListingFormData) {
      updateAdminListingType(this.editListingFormData)
        .then(() => {
          this.editListingOpen = false;
          this.load();
          ListingsModule.getListingIconTypes();
        })
        .catch(e => {
          openErrorSnackbar.call(this, e.toString());
        });
    }
  }

  private deleteId = 0;
  private deleteOpen = false;

  deleteListing() {
    deleteAdminListingType(this.deleteId)
      .then(response => {
        const success = response.data;
        this.$log(success);
        if (!success) {
          openErrorSnackbar.call(
            this,
            'Cannot delete listing: server delete function returned false',
          );
        }
        this.deleteOpen = false;
        this.deleteId = 0;
        this.load();
      })
      .catch(e => {
        openErrorSnackbar.call(this, e.toString());
      });
  }

  setDeleteListing(listing: ListingType) {
    this.deleteOpen = true;
    this.deleteId = listing.listingTypeId;
  }

  // From Approval.vue
  load() {
    this.listingTypes = getAdminListingTypes()
      .then(r => r.data)
      .catch((e: AxiosError) => {
        // format and re-throw error so vue-promise catches
        if (e && e.response) {
          throw e.response.data.message;
        }
        throw e;
      });
  }
  created() {
    this.load();
  }
}
