
import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { openErrorSnackbar } from '@/utils/components';
import GButton from '@/components/gsk-components/GskButton.vue';
import { ListingType } from '@/types/listing-types.types';
import schema from './listing.schema.json';

@Component({
  components: {
    'monaco-editor': () => import('vue-monaco'),
    GTextfield,
    GButton,
  },
})
export default class ListingTemplateEditor extends Vue {
  @Prop() readonly open!: boolean;
  @Prop() readonly title!: string;
  @Prop({ type: Object, required: true }) readonly listingType!: ListingType;

  private editor: import('monaco-editor').editor.IStandaloneCodeEditor | null = null;
  private code = '';
  public onResize = debounce(this.resizeEditor.bind(this), 200);

  created() {
    this.$log('created');
    window.addEventListener('resize', this.onResize);
    this.code = JSON.stringify(this.listingType.listingTemplate, null, 2);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  loadCode(fileCode: string): void {
    this.code = fileCode;
  }

  @Watch('code')
  public handleResize() {
    this.resizeEditor();
  }

  public resizeEditor() {
    if (this.editor) {
      this.editor.layout();
    }
  }

  @Emit('update:open')
  public update(isOpen: boolean): boolean {
    return isOpen;
  }

  saveTemplate() {
    // Check for schema errors within JSON
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const monaco = (this.$refs.editor as any).monaco;
    const markers = monaco.editor.getModelMarkers() as import('monaco-editor').editor.IMarker[];
    const marker = markers.find(value => value.severity >= 4);
    if (marker) {
      openErrorSnackbar.call(
        this,
        `Ln ${marker.startLineNumber}, Col ${marker.startColumn}: ${marker.message}`,
      );
      return;
    }

    this.listingType.listingTemplate = JSON.parse(this.code);

    this.$emit('save', this.listingType);
    this.close();
  }

  public close(): void {
    this.update(false);
  }

  editorDidMount(editor: import('monaco-editor').editor.IStandaloneCodeEditor) {
    this.editor = editor;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const monaco = (this.$refs.editor as any).monaco;
    const model = editor.getModel();
    if (model === null) {
      return;
    }

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: 'http://example.com/listing.schema.json',
          fileMatch: [model.uri.toString()],
          schema: schema,
        },
      ],
    });
  }
}
